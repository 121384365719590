import Color from "color";
import { toLowerCase } from "./string.utils";

export enum ContextColor {
  fg = 'fg',
  bg = 'bg',
  primary = 'primary',
  // Alert = 'alert',
  // Attention = 'attention',
  // Neutral = 'neutral',
  // Positive = 'positive',
}

export const ColorPalette = {
  red: '#E82A00',
  blue: '#0E51D3',
  green: '#44C20F',
  yellow: '#FFCD1B',
  white: '#FFFFFF',
  gray: '#828B99',
  dark: '#080814',
  black: '#000000',
  magenta: 'ff1f53',
}
export type ColorName = keyof typeof ColorPalette;

export const CSSColorKeywords = ['inherit', 'transparent', 'currentColor'];
export const isCSSColorKeyword = (s?: Sometimes<string>) => {
  if (!s) return false;
  return CSSColorKeywords.includes(s);
}

export const isBrightColor = (colorHex?: string | null) => colorHex ? Color(colorHex || '#000').luminosity() >= .48 : false;

export function getContrastColorHex(colorHex?: string | null) {
  return isBrightColor(colorHex) ? ColorPalette.black : ColorPalette.white;
}

export function isHexCode(input?: string | null) {
  if (!input) return false;
  return /^#[0-9A-F]{6}$/i.test(input) || /^#[0-9A-F]{3}$/i.test(input) || /^#[0-9A-F]{8}$/i.test(input);
}

export function getContextColorStyle(contextColorName?: ContextColor, color?: string | null): Record<string, string> {
  if (!contextColorName || !color) {
    // console.warn('useContextColorStyle must be supplied with both a context color name and color name.');
    return {} as Record<string, string>;
  }
  const colorName = toLowerCase(contextColorName);
  if (isHexCode(color)) {
    const colorObj = Color(color);
    const h = colorObj.hue();
    const s = colorObj.saturationl() + '%';
    const l = colorObj.lightness() + '%';
    const contrastColor = Color(getContrastColorHex(color));
    const ch = contrastColor.hue();
    const cs = contrastColor.saturationl() + '%';
    const cl = contrastColor.lightness() + '%';
    return {
      [`--${colorName}-h`]: h + '',
      [`--${colorName}-s`]: s,
      [`--${colorName}-l`]: l,
      [`--${colorName}-hsl`]: [h, s, l].join(','),
      [`--${colorName}_h`]: ch + '',
      [`--${colorName}_s`]: cs,
      [`--${colorName}_l`]: cl,
      [`--${colorName}_hsl`]: [ch, cs, cl].join(','),
    }
  } else {
    return {
      [`--${colorName}-h`]: `var(--${color}-h)`,
      [`--${colorName}-s`]: `var(--${color}-s)`,
      [`--${colorName}-l`]: `var(--${color}-l)`,
      [`--${colorName}-hsl`]: `var(--${color}-hsl)`,
      [`--${colorName}_h`]: `var(--${color}_h)`,
      [`--${colorName}_s`]: `var(--${color}_s)`,
      [`--${colorName}_l`]: `var(--${color}_l)`,
      [`--${colorName}_hsl`]: `var(--${color}_hsl)`,
    }
  }
}

export const brighten = (color: string, value: number = .5) => Color(color).lighten(value).hex();
export const darken = (color: string, value: number = .5) => Color(color).darken(value).hex();